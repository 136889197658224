import React from "react";
import Layout from "components/Layout";
import { H1, P } from "components/core"

const NotFound = () => (
  <Layout>
    <H1>404 - Page Not Found</H1>
    <P>Unfortunately we couldn't find what you were looking for :(</P>
  </Layout>
);

export default NotFound;
